import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LogoText = styled.p`
    # font-family: Merriweather, serif;
    line-height: 15px;
    text-align: center;
`;

function Logo() {
    return (
        <Link to='/'>
            <LogoText>
                Friends of the Orthodox Christian
                Monastic Sisterhood of Quebec
            </LogoText>
        </Link>
    );
}

export default Logo;
