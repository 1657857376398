import React from 'react';
import { StyledH1, StyledH2, StyledP } from '../styles/common';
import { Divider } from 'antd';
import Slides, { ImagesSlides } from '../components/Slides';

function News() {
    return (
        <>
            <StyledH1>Stay in the Loop</StyledH1>

            <StyledH2>See the Monastery</StyledH2>
            <StyledP>
                Here are some photos showing the beauty of the Monastery. We hope that you can take time to visit.
            </StyledP>
            <Divider />
            <ImagesSlides />

            <StyledH2>Travel to Canada</StyledH2>
            <StyledP>
                Note that there are some unique challenges to international travel. You can find out more on <a href='https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/Canada.html'>Travel.State.Gov</a>.
            </StyledP>
        </>
    );
}

export default News;