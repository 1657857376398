import React from 'react';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styled from 'styled-components';

const MAIN_URLS: string[] = [
    '/slides/main/osmoq_slide1.jpg',
    '/slides/main/osmoq_slide2.jpg',
];

const IMAGE_URLS: string[] = [
    '/slides/images/2Construction-progress-phase-1--scaled.webp',
    '/slides/images/3-Construction-progress-phase-1-scaled.webp',
    '/slides/images/4.Construction-progress-phase-1-scaled.webp',
    '/slides/images/5.Construction-progress-phase-1-scaled.webp',
    '/slides/images/6Construction-progress-phase-1-scaled.webp',
    '/slides/images/Construction-progress-phase-1-scaled.webp',
    '/slides/images/OSMOQ-CONSTRUCTION-MONASTERY-9.jpeg',
    '/slides/images/OSMOQ-construction-monastery-11.webp',
    '/slides/images/OSMOQ-construction-monastery-12.webp',
    '/slides/images/OSMOQ-construction-monastery-13.webp',
    '/slides/images/OSMOQ-construction-monastery-14.webp',
    '/slides/images/OSMOQ-construction-monastery-18-scaled.webp',
    '/slides/images/OSMOQ-construction-monastery-22-scaled.webp',
    '/slides/images/OSMOQ-construction-monastery-24-1-scaled.jpg',
    '/slides/images/OSMOQ-construction-monastery-27.jpg',
    '/slides/images/OSMOQ-construction-monastery-28-1.jpg',
    '/slides/images/OSMOQ-construction-monastery-5-scaled.webp',
    '/slides/images/Osmoq-construction-monastery-2-scaled.webp',
    '/slides/images/Osmoq-construction-monastery-3-scaled.webp',
];


const ImageDiv = styled.div<{ height: number }>`
    width: 100%;
    height: ${({ height }) => height}px;
    display: flex;
    flex-direction: horizontal;
`;

type SlidesProps = {
    imageUrls: string[];
    objectFit?: 'cover' | 'contain';
    height?: number;
};

function Slides(props: SlidesProps) {
    const {
        imageUrls,
        objectFit = 'cover',
        height = 450,
    } = props;

    return (
        <Zoom
            scale={1.4}
            indicators={true}
            duration={5000}
            transitionDuration={1000}
        >
            {imageUrls.map((imageUrl, index) => (
                <ImageDiv
                    key={index}
                    height={height}
                >
                    <img
                        style={{
                            objectFit,
                            width: '100%',
                        }}
                        alt='Slide Image'
                        src={imageUrl}
                    />
                </ImageDiv>
            ))}
        </Zoom>
    );
}

export default Slides;

export function MainSlides() {
    return (
        <Slides
            imageUrls={MAIN_URLS}
        />
    );
}

export function ImagesSlides() {
    return (
        <Slides
            imageUrls={IMAGE_URLS}
            objectFit='contain'
            height={600}
        />
    );
}
