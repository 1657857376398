import React from 'react';
import { StyledH2, StyledP } from '../styles/common';

function Contact() {

    return (
        <>
            <StyledH2>Mailing Address</StyledH2>
            <StyledP>
                OSMOQ USA, PO Box 242, Valley Farms, AZ 85191
            </StyledP>
            <StyledH2>Email Address</StyledH2>
            <StyledP>
                Send us an e-mail at <a href="mailto:info@osmoqusa.org">info@osmoqusa.org</a>
            </StyledP>
        </>
    );
}

export default Contact;