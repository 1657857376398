import React, { useMemo } from 'react';
import { StyledH1, StyledH2, StyledP } from '../styles/common';
import { useLocation } from 'react-router-dom';
import { DIRECTORS } from './Directors';

function DirectorBio() {
    const location = useLocation();
    const directorBio = useMemo(() => {
        const alias = location.pathname.split('/').pop() || '';
        return DIRECTORS[alias];
    }, [location]);

    if (!directorBio) {
        return (
            <StyledP>Director not found</StyledP>
        );
    }

    const {
        title,
        name,
        bio,
    } = directorBio;

    return (
        <>
            <StyledH1>{name} - {title}</StyledH1>
            <StyledH2>Bio</StyledH2>
            <StyledP>{bio}</StyledP>
        </>
    );
}

export default DirectorBio;
