import { Divider } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { StyledH1, StyledH2, StyledP } from '../styles/common';

function About() {

    return (
        <>
            <StyledH1>About Us</StyledH1>
            <StyledP>
                Our founding directors have experienced first-hand the grace and unique beauty of the Monastery of the Virgin Mary the Consolatory, as well as the efforts of the Sisterhood over many years to follow the monastic traditions of the Orthodox Church. We wish to share this treasure with all of you, as well as to provide whatever assistance we can to them.
            </StyledP>
            <Divider />
            <StyledP>
                The Sisters have encountered and endured various struggles while striving to achieve their short - and long - term goals, and in our desire to help them we have created the Friends of the Orthodox Christian Monastic Sisterhood of Quebec. Our goal is to raise funds in the United States to assist with their various projects, and to share some of their burden.
            </StyledP>
            <Divider />
            <StyledP>
                We are currently in the process of filing for public charity status under section 501(c)(3) of the USA’s IRS Code.We expect that any donations supporting our organization will now and in the future be tax - deductible to the extent allowed by the IRS; you can find out more about <Link to='/process'>Our Process</Link>.
            </StyledP>
            <Divider />
            <StyledP>
                While our official name describes who we are, we know that it is long!  So to make things a bit easier we took some inspiration from another organization, based in Quebec, that is also dedicated to helping the Monastery with fundraising to help meet the Monastery's goals. This organization is called OSMOQ(Organisation de Soutien des Moniales Orthodoxes du Quebec), and to emphasize our parallel goals we adopted the name OSMOQ USA. We have no official relationship to this other organization beyond our shared goals, and we operate completely independent of it; in addition, because we are organized in the USA, we have different laws and regulations that we adhere to. If you are Canadian, then you should visit their website <a href='https://www.osmoq.org'>here</a>. But if you are from the USA, then we hope you can support the Monastery through our organization! <Link to='/contact'>Contact us for more information</Link>.
            </StyledP>

            <StyledH2>Our Directors</StyledH2>
            <StyledP>
                You can learn more about OSMOQ USA's founding directors and their love for the Monastery of the Virgin Mary the Consolatory on our <Link to='./directors'>Directors Page</Link>.
            </StyledP>
        </>
    );
}

export default About;