import React from 'react';
import { StyledH1, StyledP } from '../styles/common';
import { Divider } from 'antd';

function Mission() {

    return (
        <>
            <StyledH1>Our Mission</StyledH1>
            <StyledP>
                Our mission, plain and simple, is to help support the Monaster's building projects through fundraising initiatives in the United States.
            </StyledP>
            <Divider />
            <StyledP>
                The Monastery and their supporters have had several fundraising events in the past. These initiatives have provided important support, yet there is still a need as the Monastery's resources and income remain limited, especially as they continue to deal with the ramifications of the Covid-19 pandemic and its impact on donations and in-person visits.
            </StyledP>
            <Divider />
            <StyledP>
                One of the main financial burdens on the Sisterhood right now is the construction of new living quarters. This building will not only provide individual rooms for the Nuns, but also kitchens, workshops, small meeting rooms, and several small chapels. OSMOQ USA's main focus is to help bring about the completion of this new building.
            </StyledP>
            <Divider />
            <StyledP>
                The mission of OSMOQ USA continues beyond the construction of the of the new living quarters. The needs of the monastery are ongoing, and we want to be a part of their future as they renovate existing buildings for new uses, design and build a church, and require equipment to help them provide for their day-to-day needs.
            </StyledP>
        </>
    );
}

export default Mission;