import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter, RouterProvider
} from 'react-router-dom';
import Home from './pages/Home';
import './global.css';
import CommonLayout from './Layout';
import About from './pages/About';
import Mission from './pages/Mission';
import Process from './pages/Process';
import News from './pages/News';
import Contact from './pages/Contact';
import NotFound from './pages/404';
import Directors from './pages/Directors';
import DirectorBio from './pages/DirectorBio';

const container = document.getElementById('root');

const router = createBrowserRouter([
    {
        path: '/',
        Component: CommonLayout,
        children: [
            {
                index: true,
                Component: Home,
            },
            {
                path: 'about',
                children: [
                    {
                        index: true,
                        Component: About,
                    },
                    {
                        path: 'directors',
                        children: [
                            {
                                index: true,
                                Component: Directors,
                            },
                            {
                                path: '*',
                                Component: DirectorBio,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'mission',
                Component: Mission,
            },
            {
                path: 'process',
                Component: Process,
            },
            {
                path: 'news',
                Component: News,
            },
            {
                path: 'contact',
                Component: Contact,
            },
        ],
        ErrorBoundary: NotFound,
    },
]);

const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
