import React from 'react';
import { StyledH1, StyledH2, StyledP } from '../styles/common';
import { Divider } from 'antd';

function Process() {

    return (
        <>
            <StyledH1>Learn About Our Process</StyledH1>

            <StyledH2>Grant-Making Process Details</StyledH2>
            <StyledP>
                Here is some information and details about the grant-making process we follow.
            </StyledP>
            <Divider />
            <StyledP>
                We are a grant-making organization, meaning that the Monastery applies to us for grants; the grant proposals contain detailed project information as well as timelines and budgets. Our directors then meet and evaluate the merits of each grant proposal, determining (1) whether it fits within our organization's goals, (2) the feasibility of project completion according to the timeline and budget provided, and (3) how the requested amount fits with our current financial and near-term future fundraising abilities. If there are proposal deficiencies, then we provide feedback to help align the proposal with our evaluation criteria. If the grant proposal is accepted, then we provide the response in writing, and provide the funds in stages to meet the proposed budget timeline. We also require quarterly reports on project status until completion.
            </StyledP>
            <Divider />
            <StyledP>
                It is important to note that our directors have the responsibility to assure our donors that the funds are used according to the non-profit goals of our organization.
            </StyledP>
            <Divider />
            <StyledP>
                It is also important to note that your donations cannot be earmarked according to your specific requests. Our directors retain the sole right and responsibility to use donations in the way that we determine meets our organizations goals according to our discretion. This requirement comes from IRS regulations, and it makes sense to work hard to prevent the misuse of funds.
            </StyledP>
            <Divider />
            <StyledP>
                Nevertheless, we are open about our funding priorities: we wish to help the Sisters with the construction of their new living quarters, as well as to help renovate their existing structures for new uses. We hope that you will join us in helping them achieve these goals!
            </StyledP>

            <StyledH2>Tax-Deductibility</StyledH2>
            <StyledP>
                (work in progress) information and details about our current status with respect to tax-deductibility of donations made to our organization [more info to come].
            </StyledP>
        </>
    );
}

export default Process;
