import React, { useMemo } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import Logo from './Logo';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

type Page = {
    label: string;
    children?: Record<string, Page>;
};

type PageSet = Record<string, Page>;

const pages: PageSet = {
    '/': {
        label: 'Home',
    },
    '/about': {
        label: 'About OSMOQ USA',
        children: {
            '/directors': {
                label: 'Directors',
                children: {
                    '/mseidel': {
                        label: 'Mark Seidel',
                    },
                    '/mlykos': {
                        label: 'Martine Lykos',
                    },
                    '/akoult': {
                        label: 'Alexandra Kuolt',
                    },
                },
            },
        },
    },
    '/mission': {
        label: 'Our Mission',
    },
    '/process': {
        label: 'Our Process',
    },
    '/news': {
        label: 'News',
    },
    '/contact': {
        label: 'Contact Us',
    },
};

function CommonLayout() {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const location = useLocation();
    const navigate = useNavigate();
    const pageKeys = useMemo(() => {
        return location.pathname.split('/')
            .filter(key => key)
            .map(key => `/${key}`);
    }, [location]);

    const {
        pageCrumbs,
    } = useMemo(() => {
        let crumbKey = '';
        let page: Page | undefined = { label: '', children: pages };
        const pageCrumbs: string[][] = [];
        for (const key of pageKeys) {
            page = page?.children?.[key];
            if (!page) break;
            crumbKey += key;
            pageCrumbs.push([crumbKey, page.label]);
        }
        return { pageCrumbs };
    }, [pageKeys]);

    return (
        <Layout>
            <Header style={{ display: 'flex', backgroundColor: colorBgContainer }}>
                <div style={{ flex: '0 0 auto', maxWidth: '200px' }}>
                    <Logo />
                </div>
                <Menu
                    mode='horizontal'
                    items={
                        Object.entries(pages).map(([key, page]) => ({
                            key,
                            label: page.label,
                        }))
                    }
                    style={{ flex: 1, minWidth: 0, justifyContent: 'end' }}
                    selectedKeys={[pageKeys[0]]} // top level only
                    onSelect={info => navigate(info.key)}
                />
            </Header>
            <Content style={{ padding: '0 48px' }}>
                <Breadcrumb
                    style={{ margin: '16px 0' }}
                >
                    <Breadcrumb.Item>
                        <Link to='/'>OSMOQ USA</Link>
                    </Breadcrumb.Item>
                    {pageCrumbs.map(([key, label]) => (
                        <Breadcrumb.Item key={key}>
                            <Link to={key}>{label}</Link>
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 280,
                        padding: 24,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Outlet />
                </div>
            </Content>
            <Footer>
                Copyright © {new Date().getFullYear()} OSMOQ - All Rights Reserved.
            </Footer>
        </Layout>
    );
}

export default CommonLayout;