import React from 'react';
import { MainSlides } from '../components/Slides';
import { Divider } from 'antd';
import { StyledH1, StyledH2, StyledP } from '../styles/common';
import { Link } from 'react-router-dom';

function Home() {

    return (
        <>
            <StyledH1>Welcome to OSMOQ USA!</StyledH1>
            <MainSlides />

            <StyledH2>Welcome!</StyledH2>
            <StyledP>
                We are an organization supporting various projects of the nuns at the Greek Orthodox Monastery of the Virgin Mary the Consolatory in Quebec, Canada. Our current focus is to help the Monastery in completing the construction of the new living quarters for the resident sisters.
            </StyledP>
            <Divider />
            <StyledP>
                We hope you browse our site to get more information on how you can help the Sisters, and we also encourage you to visit the <a href="https://www.monasterevmc.org/">website of the Monastery</a>.
            </StyledP>
            <ul>
                <li><Link to='/about'>Learn more about us</Link></li>
                <li><Link to='/mission'>Read our mission</Link></li>
                <li><Link to='/process'>Understand our process</Link></li>
                <li><Link to='/news'>See photos and news</Link></li>
                <li><Link to='/contact'>Send us an email</Link></li>
            </ul>
        </>
    );
}

export default Home;