import styled from 'styled-components';

export const StyledP = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    unicode-bidi: isolate;
    -webkit-font-smoothing: antialiased;
`;

export const StyledH1 = styled.h1`
    font-family: Merriweather, serif;
    font-size: 24px;
    font-weight: 700;
    color: rgb(28, 117, 188);
    text-transform: uppercase;
    text-align: center;
`;

export const StyledH2 = styled.h2`
    font-family: Merriweather, serif;
    font-size: 18px;
    font-weight: 700;
    color: rgb(28, 117, 188);
    text-transform: uppercase;
`
