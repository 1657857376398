import React, { Fragment } from 'react';
import { Divider } from 'antd';
import { StyledH1, StyledH2, StyledP } from '../styles/common';
import { Link } from 'react-router-dom';

type Director = {
    title: string;
    name: string;
    bio: string;
};

export const DIRECTORS: Record<string, Director> = {
    'mseidel': {
        title: 'Director and President',
        name: 'Mark Seidel',
        bio: 'Coming soon..',
    },
    'mlykos': {
        title: 'Director',
        name: 'Martine Lykos',
        bio: 'Coming soon..',
    },
    'akuolt': {
        title: 'Director',
        name: 'Alexandra Kuolt',
        bio: 'Coming soon..',
    },
};

function Directors() {

    return (
        <>
            <StyledH1>Meet our Directors</StyledH1>
            <StyledP>
                Our founding directors love to spend time at the Monastery of the Virgin Mary the Consolatory and experience its unique beauty and grace. We have visited the Monastery many, many times over the years, and always treasure our time spent there. After receiving these blessings, we all felt it was long overdue to play our part in helping the Sisterhood by creating this organization. We want to help them in whatever way that we can, and we hope that you can experience the grace and beauty and blessings, too.
            </StyledP>

            {Object.entries(DIRECTORS).map(([alias, director]) => (
                <Fragment key={alias}>
                    <StyledH2>{director.title}</StyledH2>
                    <StyledP>
                        {director.name} - <Link to={`./${alias}`}>read bio</Link>
                    </StyledP>
                    <Divider />
                </Fragment>
            ))}
        </>
    );
}

export default Directors;
